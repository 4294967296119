<template>
    <card>
      <body-card style="margin: 0;border: none;">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('dealer.application_form') }}</h4>
          </template>
          <template v-slot:body>
<!--            <b-tabs content-class="mt-0" fill>-->
<!--              &lt;!&ndash;  Tab of Application Start  &ndash;&gt;-->
<!--              <b-tab :title="$t('license_management.application')" active>-->
                <div v-if="loading">
                  <Loading />
                </div>
                <b-overlay v-else>
                  <div class="p-3">
                    <b-row>
                      <b-col sm="12">
                        <div class="text-right">
                          <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                            <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                          </b-button>
                        </div>
                        <div>
                          <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                            <div class="" style="width:50%"><b>{{ $t('teaGardenService.application_id') }}</b> : {{
                              appDetail.app_id }}</div>
                            <div class="text-right" style="width:50%"><b>{{ $t('globalTrans.application_date') }}</b> : {{
                              appDetail.created_at | dateFormat }}</div>
                          </div>
                          <div class="stepper-wrapper">
                            <div :class="item.value <= appDetail.status ? `stepper-item completed` : `stepper-item`"
                              v-for="(item, index) in stepList" :key="index">
                              <div class="step-counter">
                                <i v-if="item.value <= appDetail.status" class="ri-check-fill"></i>
                                <span v-else>{{ $n(index+1) }}</span>
                              </div>
                              <div class="step-name">{{ item.text }}</div>
                            </div>
                          </div>
                          <!-- Application view start -->
                          <div class="application-form-wrapper application-form-view-wrapper mt-5">
                            <div>
                              <h5
                                style="border-bottom: 2px solid #2f3542;width: 65%;margin: 3rem auto;padding-bottom: 5px;text-align: center;">
                                {{ $t('teaGardenPduService.course_application') }}</h5>
                            </div>
                            <div class="application-itmes">
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGardenPanel.applicant_info')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.name')" label-for="ApplicantNameEn">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.applicant_name_en :
                                          appDetail.applicant_name_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.father_name')" label-for="DesignationEn">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.father_name_en :
                                          appDetail.father_name_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.mother_name')" label-for="DesignationEn">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.mother_name_en :
                                          appDetail.mother_name_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.contact_no')" label-for="DesignationEn">
                                        <p class="input-field">{{ appDetail.contact_no | numberConvert }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.email')" label-for="email">
                                        <p class="input-field">{{ appDetail.email }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.nationality')" label-for="nationality">
                                        <p class="input-field">{{ getNationalityName(appDetail.nationality) }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.dob')" label-for="date of birth">
                                        <p class="input-field">{{ appDetail.date_of_birth | dateFormat }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                              <!--  Present Address   -->
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('globalTrans.present_address')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.area_type')" label-for="AreaType">
                                        <p class="input-field">{{ getAreaTypeName(appDetail.pr_area_type_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <!-- City Corporation Area Type -->
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.division')" label-for="Division">
                                        <p class="input-field">{{ getDivisionName(appDetail.pr_division_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.district')" label-for="District">
                                        <p class="input-field">{{ getDistrictName(appDetail.pr_district_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pr_area_type_id === 1">
                                      <b-form-group :label="$t('globalTrans.city_corporation')" label-for="CityCorporation">
                                        <p class="input-field">{{ getCityCorName(appDetail.pr_city_corporation_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pr_area_type_id === 2 || appDetail.pr_area_type_id === 3">
                                      <b-form-group :label="$t('globalTrans.upazila')" label-for="Upazila">
                                        <p class="input-field">{{ getUpazillaName(appDetail.pr_upazila_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pr_area_type_id === 2">
                                      <b-form-group :label="$t('globalTrans.pouroshova')" label-for="puroshova">
                                        <p class="input-field">{{ getPouroName(appDetail.pr_pauroshoba_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pr_area_type_id === 3">
                                      <b-form-group :label="$t('globalTrans.union')" label-for="Union">
                                        <p class="input-field">{{ getUnionName(appDetail.pr_union_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.ward')" label-for="Ward">
                                        <p class="input-field">{{ getWardName(appDetail.pr_ward_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('bazarMonitoring.post_code')" label-for="PostCode">
                                        <p class="input-field">{{ appDetail.pr_post_code ? $n(appDetail.pr_post_code, { useGrouping: false }) : '' }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="12">
                                      <b-form-group :label="$t('globalTrans.address')" label-for="AddressEn">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.pr_address_en :
                                          appDetail.pr_address_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                              <!--  /Present Address   -->

                              <!--  Permanent Address   -->
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('globalTrans.permanent_address')" label-size="md"
                                              label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.area_type')" label-for="AreaType">
                                        <p class="input-field">{{ getAreaTypeName(appDetail.pm_area_type_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <!-- City Corporation Area Type -->
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.division')" label-for="Division">
                                        <p class="input-field">{{ getDivisionName(appDetail.pm_division_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.district')" label-for="District">
                                        <p class="input-field">{{ getDistrictName(appDetail.pm_district_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pm_area_type_id === 1">
                                      <b-form-group :label="$t('globalTrans.city_corporation')" label-for="CityCorporation">
                                        <p class="input-field">{{ getCityCorName(appDetail.pm_city_corporation_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pm_area_type_id === 2 || appDetail.pm_area_type_id === 3">
                                      <b-form-group :label="$t('globalTrans.upazila')" label-for="Upazila">
                                        <p class="input-field">{{ getUpazillaName(appDetail.pm_upazila_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pm_area_type_id === 2">
                                      <b-form-group :label="$t('globalTrans.pouroshova')" label-for="puroshova">
                                        <p class="input-field">{{ getPouroName(appDetail.pm_pauroshoba_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6" v-if="appDetail.pm_area_type_id === 3">
                                      <b-form-group :label="$t('globalTrans.union')" label-for="Union">
                                        <p class="input-field">{{ getUnionName(appDetail.pm_union_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('globalTrans.ward')" label-for="Ward">
                                        <p class="input-field">{{ getWardName(appDetail.pm_ward_id) }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('bazarMonitoring.post_code')" label-for="PostCode">
                                        <p class="input-field">{{ appDetail.pm_post_code ? $n(appDetail.pm_post_code, { useGrouping: false }) : '' }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="12">
                                      <b-form-group :label="$t('globalTrans.address')" label-for="AddressEn">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.pm_address_en :
                                            appDetail.pm_address_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                              <!--  /Permanent Address   -->

                              <!--  Educational Info   -->
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGardenPduService.educational_info')" label-size="md"
                                  label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="12">
                                      <b-table-simple resonsive bordered class="mt-3 mb-0">
                                        <b-thead>
                                          <b-tr>
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('teaGardenPduService.qualification') }}</b-th>
                                            <b-th>{{ $t('globalTrans.year') }}</b-th>
                                            <b-th>{{ $t('teaGardenPduService.result') }}</b-th>
                                            <b-th>{{ $t('teaGardenPduService.main_subject') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="appDetail.educational_infos.length">
                                            <b-tr v-for="(item, index) in appDetail.educational_infos" :key="index">
                                              <b-td>{{ $n(index + 1) }}</b-td>
                                              <b-td>{{ getQualification(item) }}</b-td>
                                              <b-td>{{ $n(item.passing_year, { useGrouping: false }) }}</b-td>
                                              <b-td>{{ item.result }}</b-td>
                                              <b-td>{{ currentLocale === 'en' ? item.main_subject_en : item.main_subject_bn }}</b-td>
                                            </b-tr>
                                          </template>
                                          <template v-else>
                                            <b-tr>
                                              <b-td colspan="5" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                          </template>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                              <!--  /Educational Info   -->

                              <!--  Employment Info   -->
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGardenPduService.employment_info')" label-size="md"
                                              label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="12">
                                      <b-table-simple resonsive bordered class="mt-3 mb-0">
                                        <b-thead>
                                          <b-tr>
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('dealer.company_name') }}</b-th>
                                            <b-th>{{ $t('globalTrans.designation') }}</b-th>
                                            <b-th>{{ $t('globalTrans.start_date') }}</b-th>
                                            <b-th>{{ $t('globalTrans.end_date') }}</b-th>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="appDetail.employment_infos.length">
                                            <b-tr v-for="(item, index) in appDetail.employment_infos" :key="index">
                                              <b-td>{{ $n(index + 1) }}</b-td>
                                              <b-td>{{ currentLocale === 'en' ? item.company_name_en : item.company_name_bn }}</b-td>
                                              <b-td>{{ currentLocale === 'en' ? item.designation_en : item.designation_bn }}</b-td>
                                              <b-td>{{ item.start_date | dateFormat }}</b-td>
                                              <b-td>
                                                <template v-if="!item.is_current">{{ item.end_date | dateFormat }}</template>
                                                <template v-else>{{ $t('teaGardenPduService.continuing') }}</template>
                                              </b-td>
                                            </b-tr>
                                          </template>
                                          <template v-else>
                                            <b-tr>
                                              <b-td colspan="5" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                          </template>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                              <!--  /Employment Info   -->
                              <div class="group-form-card">
                                <b-form-group label-cols-lg="3" :label="$t('teaGardenPduService.expense_type')"
                                  label-size="md" label-class="font-weight-bold pt-0" class="form-view-item">
                                  <b-row>
                                    <b-col sm="12">
                                      <b-form-group>
                                        <p class="input-field">{{ getExpenseType(appDetail.expense_type) }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                  <b-row v-if="appDetail.expense_type !== 3">
                                    <b-col sm="6">
                                      <b-form-group :label="$t('dealer.company_name')"
                                        label-for="Company Name">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                      <b-form-group :label="$t('teaGardenConfig.company_address')"
                                                    label-for="Company Name">
                                        <p class="input-field">{{ currentLocale === 'en' ? appDetail.company_address_en : appDetail.company_address_bn }}</p>
                                      </b-form-group>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="12" class="text-left">
                        <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-overlay>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import Loading from '@/components/loading/Details.vue'
import { applicantInfoShowApi } from '../../api/routes'
// import Pdf from './details-pdf'
export default {
  name: 'Details',
  components: {
    Loading
  },
  data () {
    return {
      // loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {
        id: 0,
        applicant_name_en: '',
        applicant_name_bn: '',
        designation_en: '',
        designation_bn: '',
        tea_garden_name_en: '',
        tea_garden_name_bn: '',
        area_type_id: 0,
        division_id: 0,
        district_id: 0,
        city_corporation_id: 0,
        upazila_id: 0,
        pauroshoba_id: 0,
        union_id: 0,
        ward_id: 0,
        post_code: '',
        address_en: '',
        address_bn: '',
        have_other_tea_garden: 1,
        has_investing_capability: 1,
        income_tax_amount: '',
        bank_solvency_certificate: '',
        reason_of_rejection_en: '',
        reason_of_rejection_bn: '',
        trade_license_no: '',
        chief_executive_name_en: '',
        chief_executive_name_bn: '',
        mobile: '',
        email: ''
      },
      users: []
    }
  },
  created () {
    this.getAppDetail()
  },
  computed: {
    appId () {
      return this.$route.params.id
    },
    stepList () {
      const tcbStepList = [
        { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
        { value: 3, text: this.$t('globalTrans.verified'), text_en: this.$t('globalTrans.verified', 'en'), text_bn: this.$t('globalTrans.verified', 'bn') },
        { value: 4, text: this.$t('teaGardenPduService.confirmed'), text_en: this.$t('teaGardenPduService.confirmed', 'en'), text_bn: this.$t('teaGardenPduService.confirmed', 'bn') },
        { value: 5, text: this.$t('teaGardenPduService.certified'), text_en: this.$t('teaGardenPduService.certified', 'en'), text_bn: this.$t('teaGardenPduService.certified', 'bn') }
      ]
      return tcbStepList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    nationalityList () {
      return [
        { text: this.currentLocale === 'en' ? 'Bangladeshi' : 'বাংলাদেশী', value: 1, text_en: 'Bangladeshi', text_bn: 'বাংলাদেশী' },
        { text: this.currentLocale === 'en' ? 'Others' : 'অন্যান্য', value: 2, text_en: 'Others', text_bn: 'অন্যান্য' }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    qualificationList () {
      return [
        { text: this.currentLocale === 'en' ? 'SSC' : 'এসএসসি', value: 1, text_en: 'SSC', text_bn: 'এসএসসি' },
        { text: this.currentLocale === 'en' ? 'HSC' : 'এইচএসসি', value: 2, text_en: 'HSC', text_bn: 'এইচএসসি' },
        { text: this.currentLocale === 'en' ? 'Degree' : 'ডিগ্রী', value: 3, text_en: 'Degree', text_bn: 'ডিগ্রী' },
        { text: this.currentLocale === 'en' ? 'Masters' : 'মাস্টার্স', value: 4, text_en: 'Masters', text_bn: 'মাস্টার্স' },
        { text: this.currentLocale === 'en' ? 'Other' : 'অন্যান্য', value: 5, text_en: 'Other', text_bn: 'অন্যান্য' }
      ]
    },
    expenseTypeList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Employer' : 'নিয়োগকর্তা', text_en: 'Employer', text_bn: 'নিয়োগকর্তা' },
        { value: 2, text: this.currentLocale === 'en' ? 'Other Sponsor' : 'অন্যান্য স্পনসর', text_en: 'Other Sponsor', text_bn: 'অন্যান্য স্পনসর' },
        { value: 3, text: this.currentLocale === 'en' ? 'Self' : 'নিজ', text_en: 'Self', text_bn: 'নিজ' }
      ]
    }
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        // this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, applicantInfoShowApi + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
        }
        // this.loading = false
        this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getAreaTypeName (id) {
      const obj = this.$store.state.commonObj.areaTypeList.find(item => item.value === parseInt(id))
      return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
    },
    getCityCorName (id) {
      const data = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getPouroName (id) {
      const data = this.$store.state.CommonService.commonObj.municipalityList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getWardName (id) {
      const data = this.$store.state.CommonService.commonObj.wardList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getDivisionName (id) {
      const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUpazillaName (id) {
      const data = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getDistrictName (id) {
      const data = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUnionName (id) {
      const data = this.$store.state.CommonService.commonObj.unionList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    // pdfExport () {
    //     const reportTitle = this.$t('teaGardenPanel.small_tea_garden_registration')
    //     Pdf.exportPdfDetails(teaGardenServiceBaseUrl, '/configuration/report-heading/detail', 5, reportTitle, this)
    // },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 17)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, applicantInfoShowApi + '/' + this.appId, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getNationalityName (id) {
      const obj = this.nationalityList.find(item => item.value === id)
      return obj?.text
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getQualification (item) {
      let qualificationEn = ''
      let qualificationBn = ''
      if (item.qualification < 5) {
        const obj = this.qualificationList.find(qlfObj => qlfObj.value === item.qualification)
        qualificationEn = obj?.text_en
        qualificationBn = obj?.text_bn
      } else {
        qualificationEn = item.other_qualification_en
        qualificationBn = item.other_qualification_bn
      }

      return this.currentLocale === 'en' ? qualificationEn : qualificationBn
    },
    getExpenseType (id) {
      const obj = this.expenseTypeList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
